import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { Purpose } from '../services/models'
import Carousel from 'react-material-ui-carousel'
import { useNavigate } from 'react-router-dom'
import config from '../config.json'
import { useDeviceSelectors } from 'react-device-detect'

export const Purposes: React.FC = () => {
    const [t] = useTranslation()
    const navigate = useNavigate()
    const [selectors] = useDeviceSelectors(window.navigator.userAgent)
    const { isMobile } = selectors

    const getData = () => {
        return [
            {
                title: t('Increase their psychological literacy'),
                description: t('In order to understand where "normal reactions to abnormal circumstances" and where alarming symptoms are, you need to see a specialist')
            },
            {
                title: t('Create a system of personal prevention'),
                description: t('in which it is possible to minimize the risks of PTSD')
            }
        ]
    }

    const data = getData()

    return (
        <Grid container gap={3} direction="column">
            <Typography variant="h1">
                <Trans t={t}>
                    teplo - post-war psychological recovery for refugees and survivors
                </Trans>
            </Typography>
            <Typography variant="body1">
                <Trans t={t}>
                    We create <b>teplo</b> so you can:
                </Trans>
            </Typography>
            <Grid item xs={12} container alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={12} sm={8}>
                    <Carousel animation={'fade'} autoPlay={!isMobile} interval={config.timeoutSettings.carouselInterval}>
                        {data.map((purpose: Purpose, i) => {
                            return (
                                <div className="App-card" key={i} style={{ width: '100%', height: '220px' }}>
                                    <Grid gap={3} container direction="column">
                                        <Typography className="App-warning-banner" variant="h3">
                                            {purpose.title}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {purpose.description}
                                        </Typography>
                                    </Grid>
                                </div>
                            )
                        })}
                    </Carousel>
                </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent={'center'} alignItems={'center'}>
                <Button color="secondary" variant={'contained'} onClick={() => {
                    navigate('/apply#signUp')
                }}>
                    <Trans t={t}>
                        I want warmth
                    </Trans>
                </Button>
            </Grid>
        </Grid>
    )
}
