export interface IFeatureFlagService {

    /**
     * Get feature flag
     * */
    getFlag: (flag: FeatureFlag) => boolean

}

export enum FeatureFlag {
    Participants,
    Courses,
    About,
    Blog,
    MainPage
}
