import { FeatureFlag, IFeatureFlagService } from '../feature-flag-service'

export class FeatureFlagService implements IFeatureFlagService {
    private readonly packagesConfig: any

    constructor (packagesConfig: any) {
        this.packagesConfig = packagesConfig
    }

    getFlag = (flag: FeatureFlag) => {
        switch (flag) {
            case FeatureFlag.Participants:
                return this.packagesConfig.featureFlags.participants
            case FeatureFlag.Courses:
                return this.packagesConfig.featureFlags.courses
            case FeatureFlag.About:
                return this.packagesConfig.featureFlags.about
            case FeatureFlag.Blog:
                return this.packagesConfig.featureFlags.blog
            case FeatureFlag.MainPage:
                return this.packagesConfig.featureFlags.mainPage
        }
        return false
    }
}
