import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
import { failableAsync } from 'ts-failable'

import config from '../config.json'

import { ApiError } from './exceptions'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const qs = require('qs')

/**
 * Create header for request to API
 * */
const createHeader = (token: string | undefined) => {
    if (!token) {
        return {}
    }
    const headers = {
        Authorization: `Bearer ${token}`
    }
    return headers
}

/**
 * Request by post
 * */
export async function post<TRequest, TResponse> (
    token: string | undefined,
    url: string,
    request: TRequest
) {
    return await failableAsync<TResponse, ApiError>(async ({ success, failure }) => {
        // eslint-disable-next-line no-console
        console.debug(`Post on url: ${url}`)
        try {
            const result = await axios.post<TResponse>(url, request, {
                headers: createHeader(token),
                timeout: config.timeoutSettings.requestTimeout,
                withCredentials: false,
                responseType: 'json'
            })

            if (result.status !== StatusCodes.OK) {
                // eslint-disable-next-line no-console
                console.error(url, result)
                return await failure({
                    type: 'API_ERROR',
                    code: result.status,
                    error: result.statusText
                })
            }
            return await success(result.data)
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.error(e)
            return await failure({
                type: 'API_ERROR',
                code: e.code,
                error: e.response?.data?.error ? e.response.data.error : e.message
            })
        }
    })
}

/**
 * Request by get
 * */
export async function get<TResponse> (token: string | undefined, url: string, request?: any) {
    return await failableAsync<TResponse, ApiError>(async ({ success, failure }) => {
        // eslint-disable-next-line no-console
        console.debug(`Get on url: ${url}`)
        try {
            const result = await axios.get<TResponse>(url, {
                headers: createHeader(token),
                timeout: config.timeoutSettings.requestTimeout,
                responseType: 'json',
                withCredentials: false,
                params: request,
                paramsSerializer: p => {
                    return qs.stringify(p, { arrayFormat: 'repeat' })
                }
            })

            if (result.status !== StatusCodes.OK) {
                // eslint-disable-next-line no-console
                console.error(url, result)
                return await failure({
                    type: 'API_ERROR',
                    code: result.status,
                    error: result.statusText
                })
            }
            return await success(result.data)
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.error(e)
            return await failure({
                type: 'API_ERROR',
                code: e.code,
                error: e.message
            })
        }
    })
}

/**
 * Request by post
 * */
export async function put<TRequest, TResponse> (
    token: string,
    url: string,
    request: TRequest
) {
    return await failableAsync<TResponse, ApiError>(async ({ success, failure }) => {
        // eslint-disable-next-line no-console
        console.debug(`Post on url: ${url}`)
        try {
            const result = await axios.put<TResponse>(url, request, {
                headers: createHeader(token),
                timeout: config.timeoutSettings.requestTimeout,
                withCredentials: false,
                responseType: 'json'
            })

            if (result.status !== StatusCodes.OK) {
                // eslint-disable-next-line no-console
                console.error(url, result)
                return await failure({
                    type: 'API_ERROR',
                    code: result.status,
                    error: result.statusText
                })
            }
            return await success(result.data)
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.error(e)
            return await failure({
                type: 'API_ERROR',
                code: e.code,
                error: e.message
            })
        }
    })
}

/**
 * Request by delete
 * */
export async function del<TResponse> (token: string, url: string, request?: any) {
    return await failableAsync<TResponse, ApiError>(async ({ success, failure }) => {
        // eslint-disable-next-line no-console
        console.debug(`Get on url: ${url}`)
        try {
            const result = await axios.delete<TResponse>(url, {
                headers: createHeader(token),
                timeout: config.timeoutSettings.requestTimeout,
                responseType: 'json',
                withCredentials: false,
                params: request
            })

            if (result.status !== StatusCodes.OK) {
                // eslint-disable-next-line no-console
                console.error(url, result)
                return await failure({
                    type: 'API_ERROR',
                    code: result.status,
                    error: result.statusText
                })
            }
            return await success(result.data)
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.error(e)
            return await failure({
                type: 'API_ERROR',
                code: e.code,
                error: e.message
            })
        }
    })
}

export async function postFile<TResponse> (token: string, url: string, paramName: string, file: any, request?: any) {
    return await failableAsync<TResponse, ApiError>(async ({ success, failure }) => {
        const formData = new FormData()
        formData.append(paramName, file)
        if (request) {
            Object.keys(request).forEach(k => {
                formData.append(k, request[k])
            })
        }
        // eslint-disable-next-line no-console
        console.debug(`Get on url: ${url}`)
        try {
            const header = createHeader(token)
            const result = await axios.post<TResponse>(url, formData, {
                headers: Object.assign(header, {
                    'Content-Type': 'multipart/form-data'
                })
            })
            if (result.status !== StatusCodes.OK) {
                // eslint-disable-next-line no-console
                console.error(url, result)
                return await failure({
                    type: 'API_ERROR',
                    code: result.status,
                    error: result.statusText
                })
            }
            return await success(result.data)
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.error(e)
            return await failure({
                type: 'API_ERROR',
                code: e.response?.status,
                error: e.response?.data?.error ?? e
            })
        }
    })
}
