import React, { createRef, ReactNode, useContext } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

interface CaptchaContextType {
    recaptchaRef?: React.RefObject<ReCAPTCHA>
}

const CaptchaContext = React.createContext<CaptchaContextType>({ })

export interface CaptchaContextProps extends CaptchaContextType {
    children: ReactNode
}

export const useCaptchaContext = () => useContext(CaptchaContext)

export const CaptchaContextProvider = (props: CaptchaContextProps) => {
    const recaptchaRef = createRef<ReCAPTCHA>()

    return (
        <React.Fragment>
            <CaptchaContext.Provider value={{ recaptchaRef }}>
                {props.children}
            </CaptchaContext.Provider>
            {process.env.REACT_APP_RECAPTCHA_SITE_KEY && <ReCAPTCHA
                ref={recaptchaRef}
                size={'invisible'}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            />}
        </React.Fragment>
    )
}
