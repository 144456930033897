import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Link } from '@mui/material'
import { activeLanguages, activeLanguagesNames } from '../i18n'

export const LanguageSwitcher: React.FC = () => {
    const { i18n } = useTranslation()
    const [current, setCurrent] = useState(i18n.language)

    return (
        <Box justifyContent={'center'} alignItems={'center'} style={{ paddingTop: '3px' }}>
            <Link variant={'body1'} underline={'none'} style={{ cursor: 'pointer' }} onClick={() => {
                let i = activeLanguages.indexOf(current)
                if (i === activeLanguages.length - 1) {
                    i = -1
                }
                const lang = activeLanguages[i + 1]
                void i18n.changeLanguage(lang).then(() => {
                    setCurrent(lang)
                })
            }}>
                {activeLanguagesNames[current].toUpperCase()}
            </Link>
        </Box>
    )
}
