import { InputAdornment, TextField } from '@mui/material'
import { Control, Controller, FieldValues } from 'react-hook-form'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Apokrif } from './Apokrif'

interface EmailProps {
    name: string
    required: string
    disabled: boolean
    label: string
    control: Control<FieldValues, any>
}

const isValidEmail = (email: string) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

export const Email: React.FC<EmailProps> = (props) => {
    const [t] = useTranslation()
    const handleEmailValidation = (email: string) => {
        return isValidEmail(email) ? true : t('Email is not valid')
    }

    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={{ required: props.required, validate: handleEmailValidation }}
            render={({ field, fieldState: { error } }) => (
                <TextField {...field}
                    fullWidth={true}
                    error={!!error}
                    helperText={error ? error.message : null}
                    disabled={props.disabled}
                    type={'email'}
                    label={props.label}
                    InputProps={{
                        endAdornment: props.required ? <InputAdornment position="start"><Apokrif /></InputAdornment> : undefined
                    }}
                />
            )}
        />
    )
}
