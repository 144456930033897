export interface GridData<T> {
    items: T[]
    totalRows: number
}

export interface BaseFilter {
    id?: string[]
}

export interface Content {
    id: string
    status: number
    creationDate: Date
    updateDate: Date
    authorId: string
    authorOfLastUpdate: string
    avatar: string
    language: string
    tags: string[]
    description: string
    externalUrl: string
}

export type Article = Content & {
    title: string
    body: string
}

export type Course = Content & {
    title: string
}

export type ContentFilter = BaseFilter & {
    tags?: string[]
    language?: string
}

export type ArticleFilter = ContentFilter

export type CourseFilter = ContentFilter

export interface Purpose {
    title: string
    description: string
}

export interface OperationStatus {
    id: string
    succeeded: boolean
    error: string
}

export interface ParticipantRequest {
    name: string
    typeOfContact: string
    nickname: string
    participantType: string
    feedback: string
    captcha: string
}

export enum ContactType {
    Telegram = 'Telegram',
    Viber = 'Viber',
    Email = 'Email'
}

export enum SourceType {
    Instagram = 'Instagram',
    Telegram = 'Telegram',
    Friends = 'Friends',
    Others = 'Others'
}

export interface MemberRequest {
    name: string
    typeOfContact: string
    nickname: string
    telegram: string
    stressLevel: number
    physicalSecurity: boolean
    physicalSecurityComment: string
    country: string
    goals: string
    course: string
    tags?: string[]
    captcha: string
    sourceType: SourceType
    sourceTypeText?: string
}
