export interface IRoutesService {

    getPath: (path: RoutePath) => string

    signUp: () => string

}

export enum RoutePath {
    Main,
    About,
    Apply,
    Blog
}
