import React, { useEffect } from 'react'
import './static/styles/App.css'
import { Route, Routes, useLocation } from 'react-router-dom'
import './i18n'
import { useFeatureFlagService } from './hooks/use-feature-flag-service'
import { FeatureFlag } from './services/feature-flag-service'
import { Home } from './pages/Home'
import { About } from './pages/About'
import { Members } from './pages/Members'
import { Blog } from './pages/Blog'
import { useRoutesService } from './hooks/use-routes-service'
import { RoutePath } from './services/routes-service'
import { useThemeFactory } from './hooks/use-theme-factory'
import { CssBaseline, ThemeProvider, Typography } from '@mui/material'
import { CookieConsent } from 'react-cookie-consent'
import { Trans, useTranslation } from 'react-i18next'
import { CaptchaContextProvider } from './hooks/CaptchaContext'

const App = () => {
    const { pathname, hash, key } = useLocation()
    const [featureFlagService] = useFeatureFlagService()
    const [routesService] = useRoutesService()
    const [factory] = useThemeFactory()
    const [t] = useTranslation()

    useEffect(() => {
        if (hash === '') {
            window.scrollTo(0, 0)
        } else {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView()
                }
            }, 0)
        }
    }, [pathname, hash, key])

    return (
        <React.Fragment>
            <ThemeProvider theme={factory.get()}>
                <CaptchaContextProvider>
                    <CssBaseline/>
                    <Routes>
                        {featureFlagService.getFlag(FeatureFlag.MainPage) &&
                            <Route path={routesService.getPath(RoutePath.Main)} element={<Home/>}/>}
                        {featureFlagService.getFlag(FeatureFlag.About) &&
                            <Route path={routesService.getPath(RoutePath.About)} element={<About/>}/>}
                        <Route path={routesService.getPath(RoutePath.Apply)} element={<Members/>}/>
                        <Route path={routesService.getPath(RoutePath.Blog)} element={<Blog/>}/>
                    </Routes>
                </CaptchaContextProvider>
            </ThemeProvider>
            <CookieConsent
                location="bottom"
                buttonText={t('Accept')}
                cookieName="teploAcceptCookiesState"
                expires={150}
            >
                <Typography variant={'body1'}>
                    <Trans t={t}>
                        This website uses cookies to enhance the user experience
                    </Trans>
                </Typography>
            </CookieConsent>
        </React.Fragment>
    )
}

export default App
