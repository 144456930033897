import React, { useEffect, useState } from 'react'
import { Alert, Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useArticleService } from '../hooks/use-article-service'
import config from '../config.json'
import { Article } from '../services/models'
import { Link } from 'react-router-dom'

export const Blog: React.FC = () => {
    const [t, i18n] = useTranslation()
    const [articleService] = useArticleService()
    const [articles, setArticles] = useState<Article[]>([])
    const [error, setError] = useState<string>()

    useEffect(() => {
        void articleService.find(undefined, {
            tags: config.filters.blog,
            language: i18n.language
        }).then(res => {
            res.map(a => {
                setError(undefined)
                setArticles(a.items)
            }).mapError(e => {
                setError(e.error)
            })
        })
    }, [articleService, i18n.language])

    return (
        <React.Fragment>
            {articles.length > 0 && <Grid container gap={3} direction="column">
                <Typography variant="h1">
                    <Trans t={t}>
                        Blog
                    </Trans>
                </Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <Grid item container direction="row" columns={{ xs: 1, sm: 1, md: 2 }}>
                    {articles.map((a, index) => {
                        return (
                            <Grid item xs={12} sm={12} md={6} container style={{ padding: '10px' }} key={index}>
                                <Grid item container direction="row" alignItems="flex-start" justifyContent="flex-start" className="App-card">
                                    <Grid item xs={4} sm={3} md={4}>
                                        <img alt="Blog avatar" width={'100%'} src={a.avatar}/>
                                    </Grid>
                                    <Grid item xs={8} sm={9} md={8} container gap={2} justifyContent="space-between"
                                        direction="column">
                                        <Typography variant="h5">
                                            {a.title}
                                        </Typography>
                                        <Typography variant="body2">
                                            {a.description}
                                        </Typography>
                                        <Grid item container justifyContent="flex-start" direction="row">
                                            <Link className="usualLink" to={`/blog/${a.id}`}>
                                                <Trans t={t}>
                                                    Read
                                                </Trans>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>}
        </React.Fragment>
    )
}
