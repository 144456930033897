import { styled } from '@mui/material'
import React from 'react'

const Container = styled('div')({
    color: 'red',
    paddingTop: '10px',
    fontSize: '110%',
    fontWeight: 'bold'
})

export const Apokrif: React.FC = () => {
    return <Container>*</Container>
}
