import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import burlakova from '../static/images/burlakova.svg'
import gurova from '../static/images/gurova.svg'
import girl2 from '../static/images/girl2.svg'
import girl3 from '../static/images/girl3.svg'
import girl4 from '../static/images/girl4.svg'
import girl5 from '../static/images/girl5.svg'
import man1 from '../static/images/man1.svg'
import man2 from '../static/images/man2.svg'

export const Experience: React.FC = () => {
    const [t] = useTranslation()

    const getMessage = (img: any, message: string, right?: boolean) => {
        return (
            <Grid container gap={2} justifyContent={right ? 'flex-end' : 'flex-start'}
                alignItems={right ? 'flex-end' : 'flex-start'} direction={'row'}>
                {!right && <Grid item>
                    <img src={img} alt={'Avatar'} width={70}/>
                </Grid>}
                <Grid item xs={9} sm={7}>
                    <div className="App-card">
                        <Typography variant={'body2'}>
                            {message}
                        </Typography>
                    </div>
                </Grid>
                {right && <Grid item>
                    <img src={img} alt={'Avatar'} width={70}/>
                </Grid>}
            </Grid>
        )
    }

    return (
        <Grid container justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={12} sm={8} container gap={3} justifyContent={'flex-start'} alignItems={'flex-start'}
                direction={'column'}>
                <Typography variant={'h1'}>
                    <Trans t={t}>
                        The experience of being a refugee is a very difficult one
                    </Trans>
                </Typography>
                <Typography variant={'body1'}>
                    <Trans t={t}>
                        But unfortunately as well as fortunately, humanity has accumulated knowledge of how to live this
                        experience
                    </Trans>
                </Typography>
                <Grid item xs={12} sm={5}>
                    {getMessage(burlakova, t('Unfortunately, because the very fact of accumulated\n' +
                        'knowledge means that wars and wanderings in the history of\n' +
                        'of humanity'))}
                </Grid>
                <Grid item xs={12} sm={5}>
                    {getMessage(gurova, t('Fortunately - because you and I\n' +
                        'can now use this\n' +
                        'experience for the benefit of ourselves and others'))}
                </Grid>
                <Grid container gap={3} alignItems={'flex-end'} justifyContent={'flex-end'} direction={'column'}>
                    <Grid item xs={12} sm={5}>
                        {getMessage(man1, t('What can I inwardly lean on when life turned upside down?'), true)}
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        {getMessage(girl3, t("How can you plan when it's chaos all around you?"), true)}
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        {getMessage(girl2, t('How does this experience change me and my relationships?'), true)}
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        {getMessage(man2, t('What does science say about the psychological risks of warfare?'), true)}
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        {getMessage(girl4, t('What you need to take care of today, to prevent the trauma from spreading for the rest of your life?'), true)}
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        {getMessage(girl5, t('How to communicate with other people, not to go into total loneliness, but to continue to be in contact?'), true)}
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        {getMessage(man1, t('What new identity is emerging in me?'), true)}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
