import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Menu, MenuItem } from '../components/Menu'
import { WidgetBlock } from '../components/WidgetBlock'
import { Trans, useTranslation } from 'react-i18next'
import { Experience } from '../components/Experience'
import { Feedback } from '../components/Feedback'
import { CourseForm } from '../components/CourseForm'
import { Footer } from '../components/Footer'
import { SignUpBanner } from '../components/SignUpBanner'

export const Members: React.FC = () => {
    const [t] = useTranslation()

    return (
        <Grid container direction="column" className="App">
            <Grid item xs={12}>
                <Menu selected={MenuItem.Request}/>
            </Grid>

            <SignUpBanner />

            <WidgetBlock white={true} background={true}>
                <Experience/>
            </WidgetBlock>

            <WidgetBlock white={false}>
                <Grid container direction={'column'} gap={3}>
                    <Typography variant={'h1'}>
                        <Trans t={t}>
                            We will search for these meanings and supports in all eight sessions of the course with the
                            help of psychology, neurophysiology, coaching and dialogue with each other
                        </Trans>
                    </Typography>
                    <Typography variant={'body1'}>
                        <Trans t={t}>
                            It is necessary to fill out an application form to receive an invitation to the program. The course is free, and its main purpose is to support participants and provide the kind of knowledge in this crazy time that can be useful
                        </Trans>
                    </Typography>
                </Grid>
            </WidgetBlock>

            <WidgetBlock white={true}>
                <Feedback/>
            </WidgetBlock>

            <WidgetBlock white={false} id="signUp">
                <CourseForm/>
            </WidgetBlock>

            <Footer/>

        </Grid>
    )
}
