/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react'
import {
    Alert,
    Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl,
    FormControlLabel, FormHelperText,
    FormLabel,
    Grid, InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material'
import { Controller, FieldValues, useForm, useWatch } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { ContactType, Course, MemberRequest, SourceType } from '../services/models'
import { useMemberService } from '../hooks/use-member-service'
import { useCourseService } from '../hooks/use-course-service'
import config from '../config.json'
import { useSearchParams } from 'react-router-dom'
import { useCaptchaContext } from '../hooks/CaptchaContext'
import { Email } from './controls/Email'
import { Apokrif } from './controls/Apokrif'

export const CourseForm: React.FC = () => {
    const [t, i18n] = useTranslation()
    const [preferredCommunicationMode] = useState(ContactType.Email)
    const {
        handleSubmit,
        reset,
        formState: { errors },
        control,
        register,
        setValue
    } = useForm()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [offlineFormLink, setOfflineFormLink] = useState<boolean>()
    const [loading, setLoading] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [course, setCourse] = useState<Course>()
    const [memberService] = useMemberService()
    const [courseService] = useCourseService()
    const [params] = useSearchParams()
    const { recaptchaRef } = useCaptchaContext()
    const currentSourceType = useWatch({
        control,
        name: 'currentSourceType'
    })

    const setErrorMessageToForm = (err: string) => {
        setErrorMessage(err)
        setOfflineFormLink(true)
    }

    const getCourse = async () => {
        const result = await courseService.find(undefined, {
            tags: config.filters.course,
            language: i18n.language
        }, 1, 0, 'creationDate Descending')
        let currentCourse: Course | undefined
        result.map(a => {
            if (a.items.length > 0) {
                currentCourse = a.items[0]
            }
        }).mapError(e => {
            setErrorMessageToForm(t('Can\'t load course', { val: e.error }))
        })
        return currentCourse
    }

    useEffect(() => {
        void getCourse().then(c => {
            if (c) {
                setCourse(c)
            }
        })
    }, [courseService, i18n.language])

    const resetForm = () => {
        reset()
        recaptchaRef?.current?.reset()
    }

    const onSubmit = async (form: FieldValues) => {
        if (!recaptchaRef) {
            setErrorMessageToForm(t('Captcha control not founded'))
            return
        }

        setErrorMessage(undefined)
        setOfflineFormLink(false)

        let token
        try {
            token = await recaptchaRef.current?.executeAsync()

            if (!token) {
                setErrorMessageToForm(t('Captcha token is empty'))
                return
            }
        } catch (e) {
            setErrorMessageToForm(JSON.stringify(e))
            return
        }

        const currentCourse = course ?? await getCourse()
        if (!course && !currentCourse) {
            setCourse(currentCourse)
        }

        if (!course) {
            setErrorMessageToForm(t('Can\'t load course'))
            return
        }
        const tags = params.get('selected')
        const request: MemberRequest = {
            name: form.name,
            typeOfContact: preferredCommunicationMode,
            nickname: form.email,
            telegram: form.nickname,
            stressLevel: form.stressLevel,
            physicalSecurity: form.physicalSecurity === 'true',
            physicalSecurityComment: form.physicalSecurityComment,
            country: form.country,
            goals: form.goals,
            course: course.id,
            tags: tags?.split(';;;') ?? [],
            captcha: token,
            sourceType: form.sourceType,
            sourceTypeText: form.sourceTypeText
        }
        setLoading(true)
        const r = await memberService.create(request)
        r.map(status => {
            if (!status.succeeded) {
                setErrorMessage(status.error)
                recaptchaRef?.current?.reset()
            } else {
                setConfirmOpen(true)
                resetForm()
            }
        }).mapError(e => {
            setErrorMessageToForm(e.error)
            recaptchaRef?.current?.reset()
        })
        setLoading(false)
    }

    const getEmailLabel = () => {
        switch (preferredCommunicationMode) {
            case ContactType.Telegram:
                return t('@nickname')
            case ContactType.Viber:
                return t('Nickname')
            default:
                return t('E-mail')
        }
    }

    return (
        <React.Fragment>
            <Grid container gap={3} direction={'column'}>
                <Typography variant={'h1'}>
                    <Trans t={t}>
                        How to sign up?
                    </Trans>
                </Typography>
                <Typography variant={'body1'}>
                    <Trans t={t}>
                        To enroll in the program, we ask you to answer a few questions
                        and provide your contact information
                    </Trans>
                </Typography>
                <Box
                    component="form"
                    method="POST"
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <Controller
                            name={'name'}
                            control={control}
                            rules={{ validate: async (value) => !!value?.trim() ? true : await t('Name is required') }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField {...field}
                                    fullWidth={true}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    disabled={loading}
                                    label={t('Your name or whatever you want to call you, even kitty cat 😼')}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start"><Apokrif /></InputAdornment>
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <input type={'hidden'} name={'preferredCommunicationMode'} value={preferredCommunicationMode} />
                    <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <Email
                            name={'email'}
                            required={t('Email is required')}
                            disabled={loading}
                            label={getEmailLabel()}
                            control={control}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <FormLabel>
                            <Typography variant={'body2'}>
                                <Trans t={t}>
                                    How do I get in touch with you on Telegram?
                                </Trans>
                            </Typography>
                            <Typography variant={'body2'} fontStyle={'italic'}>
                                <Trans t={t}>
                                    That's where the general chat + the ability to request individual support +
                                    recordings
                                    of all the lectures + links to exercises
                                </Trans>
                            </Typography>
                        </FormLabel>
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <Controller
                            name={'nickname'}
                            control={control}
                            rules={{ validate: async (value) => !!value?.trim() ? true : await t('Nickname is required') }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField {...field}
                                    fullWidth={true}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    disabled={loading}
                                    label={'@nickname'}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start"><Apokrif /></InputAdornment>
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <FormLabel>
                            <Typography variant={'body2'}>
                                <Trans t={t}>
                                    On a scale of 1 to 10, how do you rate your current stress level?
                                </Trans>
                            </Typography>
                            <Typography variant={'body2'} fontStyle={'italic'}>
                                <Trans t={t}>
                                    Where 1 is no stress and 10 is acute stress
                                </Trans>
                            </Typography>
                        </FormLabel>
                        <RadioGroup row={true}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val, i) => {
                                return <FormControlLabel value={val} key={i}
                                    componentsProps={{ typography: { variant: 'body2' } }}
                                    control={<Radio size={'small'}
                                        disabled={loading} {...register('stressLevel', { required: true })} />}
                                    label={val}/>
                            })}
                        </RadioGroup>
                        <FormHelperText error={errors?.stressLevel !== undefined}>
                            {errors?.stressLevel && <Trans t={t}>Select option</Trans>}
                        </FormHelperText>
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 1 }}>
                        <FormLabel>
                            <Typography variant={'body2'}>
                                <Trans t={t}>
                                    Are you physically safe now?
                                </Trans>
                            </Typography>
                        </FormLabel>
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <RadioGroup row={true}>
                            <FormControlLabel value={true}
                                componentsProps={{ typography: { variant: 'body2' } }}
                                control={<Radio size={'small'} disabled={loading} {...register('physicalSecurity', { required: true })} />}
                                label={t('Yes')}/>
                            <FormControlLabel value={false}
                                componentsProps={{ typography: { variant: 'body2' } }}
                                control={<Radio size={'small'} disabled={loading} {...register('physicalSecurity', { required: true })}/>}
                                label={t('No')}/>
                        </RadioGroup>
                        <FormHelperText error={errors?.physicalSecurity !== undefined}>
                            {errors?.physicalSecurity && <Trans t={t}>Select option</Trans>}
                        </FormHelperText>
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 1 }}>
                        <FormLabel>
                            <Typography variant={'body2'}>
                                <Trans t={t}>
                                    Short comment
                                </Trans>
                            </Typography>
                        </FormLabel>
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <Controller
                            name={'physicalSecurityComment'}
                            control={control}
                            render={({ field }) => (
                                <TextField {...field}
                                    fullWidth={true}
                                    disabled={loading}
                                    multiline={true}
                                    rows={3}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 1 }}>
                        <FormLabel>
                            <Typography variant={'body2'}>
                                <Trans t={t}>
                                    What country are you currently in?
                                </Trans>
                            </Typography>
                        </FormLabel>
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <Controller
                            name={'country'}
                            control={control}
                            rules={{ validate: async (value) => !!value?.trim() ? true : await t('Country is required') }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField {...field}
                                    fullWidth={true}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    disabled={loading}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start"><Apokrif /></InputAdornment>
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 1 }}>
                        <FormLabel>
                            <Typography variant={'body2'}>
                                <Trans t={t}>
                                    What is your main request for the course? What would you like to get as a result?
                                </Trans>
                            </Typography>
                        </FormLabel>
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <Controller
                            name={'goals'}
                            control={control}
                            rules={{ validate: async (value) => !!value?.trim() ? true : await t('Goals is required') }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField {...field}
                                    fullWidth={true}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    disabled={loading}
                                    multiline={true}
                                    rows={3}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start"><Apokrif /></InputAdornment>
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <FormLabel>
                            <Typography variant={'body2'}>
                                <Trans t={t}>
                                    Share how you heard about us?
                                </Trans>
                            </Typography>
                        </FormLabel>
                        <RadioGroup row={true} onChange={(e) => setValue('currentSourceType', e.target.value)}>
                            <FormControlLabel value={SourceType.Instagram} componentsProps={{ typography: { variant: 'body2' } }}
                                control={<Radio size={'small'}
                                    disabled={loading} {...register('sourceType', { required: true })} />}
                                label="Instagram"/>
                            <FormControlLabel value={SourceType.Telegram} componentsProps={{ typography: { variant: 'body2' } }}
                                control={<Radio size={'small'}
                                    disabled={loading} {...register('sourceType', { required: true })} />}
                                label="Telegram"/>
                            <FormControlLabel value={SourceType.Friends} componentsProps={{ typography: { variant: 'body2' } }}
                                control={<Radio size={'small'}
                                    disabled={loading} {...register('sourceType', { required: true })} />}
                                label={t('Friends')}/>
                            <FormControlLabel value={SourceType.Others} componentsProps={{ typography: { variant: 'body2' } }}
                                control={<Radio size={'small'}
                                    disabled={loading} {...register('sourceType', { required: true })} />}
                                label={t('Others')}/>
                        </RadioGroup>
                        <FormHelperText error={errors?.sourceType !== undefined}>
                            {errors?.sourceType && <Trans t={t}>Select option</Trans>}
                        </FormHelperText>
                    </FormControl>
                    {currentSourceType === SourceType.Others && <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <Controller
                            name={'sourceTypeText'}
                            control={control}
                            render={({ field }) => (
                                <TextField {...field}
                                    fullWidth={true}
                                    disabled={loading}
                                    multiline={true}
                                    rows={2}
                                />
                            )}
                        />
                    </FormControl>}
                    {errorMessage && <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </FormControl>}
                    {offlineFormLink && <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <Alert severity="warning">
                            <Trans t={t}>
                                If you can't enroll on this form, follow this link and fill out the form <a href={config.messengers.offlineRegistrationForm}>Go to form</a>
                            </Trans>
                        </Alert>
                    </FormControl>}
                    <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <Button disabled={loading} type={'submit'} style={{ height: '40px' }} fullWidth={true} variant={'contained'}
                            sx={{
                                color: 'white'
                            }}>
                            <Trans t={t}>
                                Submit
                            </Trans>
                        </Button>
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 2 }}>
                        <Typography variant={'body1'}>
                            <Trans t={t}>
                                If you can't send the request by this form, please email us your information <a href="mailto:teplopsyhelp@gmail.com">teplopsyhelp@gmail.com</a>
                            </Trans>
                        </Typography>
                    </FormControl>
                </Box>
            </Grid>
            <Dialog open={confirmOpen} onClose={() => {
                setConfirmOpen(false)
            }}>
                <DialogTitle>
                    <Trans t={t}>
                        The application has been submitted! Thank you for your trust
                    </Trans>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans t={t}>
                            The invitation letter will arrive within the next 5 minutes. If you haven't received it, please check your spam folder or contact us at <a href="mailto:teplopsyhelp@gmail.com">teplopsyhelp@gmail.com</a>
                        </Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setConfirmOpen(false)
                    }}>
                        <Trans t={t}>
                            Ok
                        </Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
