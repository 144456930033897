import React from 'react'
import { Container, Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import instagram from '../static/images/instagram-black.svg'
import config from '../config.json'

export const Footer: React.FC = () => {
    const [t] = useTranslation()

    return (
        <div className="App-footer">
            <Container>
                <div style={{ height: '20px' }}></div>
                <Grid container>
                    <Grid item xs={10} container gap={2} direction={'column'}>
                        <Typography variant={'body2'} color={'white'}>
                            <Trans t={t}>
                                teplo - psychological Support Program for Ukrainian Refugees
                            </Trans>
                        </Typography>
                        <Typography variant={'body2'} color={'white'}>
                            teplopsyhelp@gmail.com
                        </Typography>
                        <Typography variant={'body2'} color={'white'}>
                            ©{(new Date()).getFullYear()} teplo
                        </Typography>
                    </Grid>
                    <Grid item xs={2} container justifyContent={'end'}>
                        {config.messengers.instagram && <a href={config.messengers.instagram} target={'_blank'} rel="noreferrer">
                            <img src={instagram} alt={'Instagram'} />
                        </a>}
                    </Grid>
                </Grid>

            </Container>
        </div>
    )
}
