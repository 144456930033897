import React, { useEffect, useState } from 'react'
import { Alert, Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import config from '../config.json'
import { useCourseService } from '../hooks/use-course-service'
import { Course, GridData } from '../services/models'
import { NavLink } from 'react-router-dom'

export const Classes: React.FC = () => {
    const [t, i18n] = useTranslation()
    const [courseService] = useCourseService()
    const [courses, setCourses] = useState<GridData<Course>>({
        items: [],
        totalRows: 0
    })
    const [error, setError] = useState<string>()

    useEffect(() => {
        void courseService.find(undefined, {
            tags: config.filters.blog,
            language: i18n.language
        }).then(res => {
            res.map(a => {
                setError(undefined)
                setCourses(a)
            }).mapError(e => {
                setError(e.error)
            })
        })
    }, [courseService, i18n.language])

    return (
        <React.Fragment>
            {courses.totalRows > 0 && <Grid container gap={3} direction="column">
                <Typography variant="h1">
                    <Trans t={t}>
                        Our support programs
                    </Trans>
                </Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <Grid item container direction="row" columns={{ xs: 1, sm: 1, md: 2 }}>
                    {courses.items.map((a, index) => {
                        return (
                            <Grid item xs={12} sm={12} md={6} container style={{ padding: '10px' }} key={index}>
                                <Grid item container direction="row" alignItems="flex-start" justifyContent="flex-start" className="App-card">
                                    <Grid item xs={4} sm={3} md={4}>
                                        <img alt="Avatar" width={'100%'} src={a.avatar}/>
                                    </Grid>
                                    <Grid item xs={8} sm={9} md={8} container gap={2} justifyContent="space-between"
                                        direction="column">
                                        <Typography variant="h5">
                                            {a.title}
                                        </Typography>
                                        <Typography variant="body2">
                                            {a.description}
                                        </Typography>
                                        <Grid item container justifyContent="flex-start" direction="row">
                                            <NavLink className="usualLink" to={'/'}>
                                                <Trans t={t}>
                                                    Read
                                                </Trans>
                                            </NavLink>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>}
        </React.Fragment>
    )
}
