import React from 'react'

interface WarningBannerProps {
    text: React.ReactNode
}

export const WarningBanner: React.FC<WarningBannerProps> = (props) => {
    return (
        <span className="App-warning-banner">
            {props.text}
        </span>
    )
}
