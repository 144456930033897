import config from '../../config.json'
import { get } from '../connector'
import { Course, CourseFilter, GridData } from '../models'
import { ICourseService } from '../course-service'

export class CourseService implements ICourseService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_CONTENT_SERVICE_API_HOST ??
            config.servers.contentServiceUrl
        // eslint-disable-next-line no-console
        console.info(
            'CONTENT_SERVICE_API_HOST: Using defaults, result:',
            this.apiUrl
        )
    }

    find = async (token?: string, filter?: CourseFilter, limit?: number, skip?: number, sort?: string) => {
        const url = `${this.apiUrl}course/find`
        const request = {
            limit,
            skip,
            sort
        }
        if (filter) {
            filter = Object.assign(filter, request)
        }
        return await get<GridData<Course>>(token, url, filter)
    }
}
