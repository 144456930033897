/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import feedbackImage from '../static/images/feedback.svg'
import page1 from '../static/images/feedback/mobile-feedback-page1.svg'
import page2 from '../static/images/feedback/mobile-feedback-page2.svg'
import { useDeviceSelectors } from 'react-device-detect'
import Carousel from 'react-material-ui-carousel'
import config from '../config.json'

export const Feedback: React.FC = () => {
    const [t] = useTranslation()
    const [selectors] = useDeviceSelectors(window.navigator.userAgent)
    const { isMobile } = selectors

    return (
        <Grid container direction={'column'} gap={3}>
            <Typography variant={'h1'}>
                <Trans t={t}>
                    Reviews of the "Artful Odyssey" course
                </Trans>
            </Typography>
            <Typography variant={'body1'}>
                <Trans t={t}>
                    Feedback from the participants of the first stream
                </Trans>
            </Typography>
            {!isMobile && <img height={'500px'} src={feedbackImage} alt={'Feedback'}/>}
            {isMobile && <Carousel autoPlay={!isMobile} interval={config.timeoutSettings.carouselInterval} >
                <div style={{ width: '100%', height: '450px' }}>
                    <img width={'100%'} src={page1} alt={'Feedback'} />
                </div>
                <div style={{ width: '100%', height: '450px' }}>
                    <img width={'100%'} src={page2} alt={'Feedback'} />
                </div>
            </Carousel>}
        </Grid>
    )
}
