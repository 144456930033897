/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Grid, Typography } from '@mui/material'
import { WarningBanner } from './WarningBanner'
import { Trans, useTranslation } from 'react-i18next'
import { SignUpButtons } from './SignUpButtons'
import { ColorType } from './TesterToEmail'
import Carousel from 'react-material-ui-carousel'
import location from '../static/images/location.svg'
import bannerImage1 from '../static/images/banner/banner-image-1.svg'
import bannerImage2 from '../static/images/banner/banner-image-2.svg'
import bannerImage4 from '../static/images/banner/banner-image-4.svg'
import { useNavigate } from 'react-router-dom'
import config from '../config.json'
import { useDeviceSelectors } from 'react-device-detect'
import { SignUpBanner } from './SignUpBanner'

export const Banner: React.FC = () => {
    const [t] = useTranslation()
    const navigate = useNavigate()
    const [selectors] = useDeviceSelectors(window.navigator.userAgent)
    const { isMobile } = selectors

    const bannerHeight = isMobile ? '410px' : '430px'

    return (
        <Grid item container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Carousel animation={'fade'} fullHeightHover={true} autoPlay={!isMobile} swipe={true} interval={config.timeoutSettings.carouselInterval}>
                    <div style={{ width: '100%', minHeight: bannerHeight }}>
                        <Grid container gap={2}>
                            <Grid item xs={12} sm={7} container gap={3} direction={'column'}>
                                {!isMobile && <div style={{ height: '20px' }}></div>}
                                <div>
                                    <WarningBanner text={<Typography component="span" variant={'h1'}>
                                        <Trans t={t}>
                                            Psychological support program
                                        </Trans>
                                    </Typography>}/>
                                </div>
                                <div>
                                    <WarningBanner text={<Typography component="span" variant={'h1'}>
                                        <Trans t={t}>
                                            for Ukrainian refugees
                                        </Trans>
                                    </Typography>}/>
                                </div>
                                <div>
                                    <Typography variant="body2">
                                        <img width={16} src={location}
                                            style={{ verticalAlign: 'text-top', marginRight: '5px' }} alt={'Location'}/>
                                        <Trans t={t}>
                                            online, free
                                        </Trans>
                                    </Typography>
                                </div>
                                <SignUpButtons color={ColorType.secondary} hideDetailedButton={true}
                                    onSignUpClick={() => {
                                        navigate('/apply#signUp')
                                    }}/>

                            </Grid>
                            <Grid item xs={12} sm={3} container justifyContent={'center'}>
                                <img width={!isMobile ? '100%' : 'auto'} height={isMobile ? '200px' : 'auto'} src={bannerImage1} alt={'Image 1'}/>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ width: '100%', minHeight: bannerHeight }}>
                        <Grid container gap={2}>
                            <Grid item xs={12} sm={7} container gap={3} direction={'column'}>
                                <div style={{ height: '5px' }}></div>
                                <div>
                                    <WarningBanner text={<Typography component="span" variant={'h1'}>
                                        <Trans t={t}>
                                            Psychological support program
                                        </Trans>
                                    </Typography>}/>
                                </div>
                                <div>
                                    <WarningBanner text={<Typography component="span" variant={'h1'}>
                                        <Trans t={t}>
                                            for Ukrainian refugees
                                        </Trans>
                                    </Typography>}/>
                                </div>
                                <Typography variant="body2" fontStyle={'italic'}>
                                    <Trans t={t}>
                                        "<b>teplo</b> is such a mental spa platform that brings together the efforts of
                                        psychologists, methodologists, IT specialists, participants in our programs, and
                                        opinion leaders
                                    </Trans>
                                </Typography>
                                <Typography variant="body2" fontStyle={'italic'}>
                                    <Trans t={t}>
                                        The goal of this joint effort is to create the tools and environment for
                                        recovery from the psychological trauma of war or any other form of violence."
                                    </Trans>
                                </Typography>
                                <Typography variant="body2">
                                    <Trans t={t}>
                                        <b>Julia Burlakova</b>, founder
                                    </Trans>
                                </Typography>
                            </Grid>
                            {!isMobile && <Grid item sm={4}>
                                <img width={'100%'} src={bannerImage2} alt={'Image 2'}/>
                            </Grid>}
                        </Grid>
                    </div>
                    <div style={{ width: '100%', minHeight: bannerHeight }}>
                        <Grid container gap={2}>
                            <Grid item xs={12} sm={7} container gap={3} direction={'column'}>
                                <div style={{ height: '5px' }}></div>
                                <div>
                                    <WarningBanner text={<Typography component="span" variant={'h1'}>
                                        <Trans t={t}>
                                            Psychological support program
                                        </Trans>
                                    </Typography>}/>
                                </div>
                                <div>
                                    <WarningBanner text={<Typography component="span" variant={'h1'}>
                                        <Trans t={t}>
                                            for Ukrainian refugees
                                        </Trans>
                                    </Typography>}/>
                                </div>
                                <Typography variant="body2" fontStyle={'italic'}>
                                    <Trans t={t}>
                                        "I understood that wartime psychological trauma had been with me forever since
                                        my first encounter with the war, when as an aspiring psychologist at age 22 my
                                        job was to meet cargo-200 in Rostov and accompany to these coffins the parents
                                        of those who had once been their living child and were now a line in the record
                                        of cargo-200
                                    </Trans>
                                </Typography>
                                <Typography variant="body2" fontStyle={'italic'}>
                                    <Trans t={t}>
                                        Since then I have sought to concentrate on peace and the creation of a better
                                        future for human beings, and in a terrible nightmare I could not imagine the
                                        return of the military context to our lives."
                                    </Trans>
                                </Typography>
                                <Typography variant="body2">
                                    <Trans t={t}>
                                        <b>Olga Gurova</b>, co-author and host of the program "Artful Odyssey"
                                    </Trans>
                                </Typography>
                            </Grid>
                            {!isMobile && <Grid item sm={4}>
                                <img width={'100%'} src={bannerImage4} alt={'Image 4'}/>
                            </Grid>}
                        </Grid>
                    </div>
                    <div style={{ width: '100%', minHeight: bannerHeight }}>
                        <SignUpBanner />
                    </div>
                </Carousel>
            </Grid>
        </Grid>
    )
}
