/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { BorderType, TesterToEmail, TesterType } from '../components/TesterToEmail'
import { Participants } from '../components/Participants'
import { Blog } from '../components/Blog'
import { MessageForm } from '../components/MessageForm'
import { Purposes } from '../components/Purposes'
import { Classes } from '../components/Classes'
import { Menu, MenuItem } from '../components/Menu'
import { WidgetBlock } from '../components/WidgetBlock'
import { FeatureFlag } from '../services/feature-flag-service'
import { Banner } from '../components/Banner'
import { Footer } from '../components/Footer'
import { Country } from '../components/Country'

export const Home: React.FC = () => {
    const [t] = useTranslation()

    return (
        <Grid container direction="column" className="App">
            <Grid item xs={12}>
                <Menu selected={MenuItem.Home}/>
            </Grid>

            <WidgetBlock white={false}>
                <Banner />
            </WidgetBlock>

            <WidgetBlock white={true} background={true}>
                <Country />
            </WidgetBlock>

            <WidgetBlock white={false}>
                <Purposes/>
            </WidgetBlock>

            <WidgetBlock white={true}>
                <TesterToEmail
                    id="PersonalityTest"
                    border={BorderType.minimal}
                    type={TesterType.long}
                    title={t('You need support if')}
                    answers={[
                        t('Because of the war you had to leave your home'),
                        t('You feel lost in an unfamiliar linguistic, domestic, or cultural environment'),
                        t('You are experiencing the effects of a traumatic situation, prolonged stress, a threat to your life or the lives of your loved ones'),
                        t('You are in a situation of total uncertainty and it is difficult for you to plan for the future'),
                        t('You take care of others (children, elderly relatives) and feel that you cannot cope with the burden of this responsibility'),
                        t('You are experiencing a loss of home, family, of a loved one or a former way of life')
                    ]}/>
            </WidgetBlock>

            <WidgetBlock white={true} featureFlag={FeatureFlag.Participants}>
                <Participants/>
            </WidgetBlock>

            <WidgetBlock white={true} featureFlag={FeatureFlag.Courses}>
                <Classes/>
            </WidgetBlock>

            <WidgetBlock white={false} featureFlag={FeatureFlag.Blog}>
                <Blog/>
            </WidgetBlock>

            <WidgetBlock white={false}>
                <MessageForm/>
            </WidgetBlock>

            <Footer />
        </Grid>
    )
}
