import { IMemberService } from '../member-service'
import config from '../../config.json'
import { MemberRequest, OperationStatus } from '../models'
import { post } from '../connector'

export class MemberService implements IMemberService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_USERS_SERVICE_API_HOST ??
            config.servers.usersServiceUrl
        // eslint-disable-next-line no-console
        console.info(
            'USERS_SERVICE_API_HOST: Using defaults, result:',
            this.apiUrl
        )
    }

    create = async (request: MemberRequest) => {
        const url = `${this.apiUrl}member`
        return await post<MemberRequest, OperationStatus>(undefined, url, request)
    }
}
