import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import belgium from '../static/images/belgium.svg'
import { ColorType, TesterToEmail, TesterType } from './TesterToEmail'

export const Country: React.FC = () => {
    const [t] = useTranslation()

    return (
        <Grid container direction="row">
            <Grid container gap={2} item xs={12} sm={12} md={8} direction="column">
                <Typography variant="h1">
                    <Trans t={t}>
                        How many Ukrainian refugees are there in the world?
                    </Trans>
                </Typography>
                <Typography variant={'body1'}>
                    <Trans t={t}>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        Since the beginning of the war, about a third of all citizens of Ukraine have been forced to leave their homes and their country. More than 12 million men, women and children. To make it easier to imagine, that's slightly more than the entire population of Belgium
                    </Trans>
                </Typography>
                <div className={'App-card'} style={{ padding: '20px' }}>
                    <Grid container direction="row">
                        <Grid item xs={4}>
                            <Grid container gap={2} direction="column">
                                <Grid item>
                                    <Typography variant={'body1'} fontWeight={'bold'}>
                                        <Trans t={t}>
                                            Number of refugees from Ukraine
                                        </Trans>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={'h1'}>
                                        <Trans t={t}>
                                            12 million
                                        </Trans>
                                        *
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={'body2'} fontStyle={'italic'}>
                                        *
                                        <Trans t={t}>
                                            data from the UN Refugee Committee
                                        </Trans>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <img alt="Banner" src={belgium} />
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container direction="column" alignItems={'end'}>
                                <Typography variant={'body1'} fontWeight={'bold'} color={'secondary'}>
                                    <Trans t={t}>
                                        Population of Belgium
                                    </Trans>
                                </Typography>
                                <Typography variant={'body1'} fontWeight={'bold'} color={'secondary'}>
                                    <Trans t={t}>
                                        11.5 million
                                    </Trans>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </div>
                <TesterToEmail
                    id="ImportantSupport"
                    color={ColorType.secondary}
                    type={TesterType.small}
                    title={t('Do you need support?')}
                    headerTypography={'h2'}
                    question={t('Have you noticed these symptoms in yourself? If you are familiar with these symptoms, we recommend that you take the course "Ingenious Odyssey"')}
                    answers={[
                        t('Intrusive memories or re-experiencing trauma'),
                        t('Avoiding thinking or talking about the experience or avoiding any people, places, or activities that serve as reminders\n'),
                        t('Changes in physical and emotional responses, such as difficulty sleeping and feelings of tension'),
                        t('Negative changes in thinking and mood, such as feelings of guilt and hopelessness')
                    ]}/>
            </Grid>
        </Grid>
    )
}
