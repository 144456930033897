import config from '../../config.json'
import { IParticipantRequestService } from '../participant-request-service'
import { OperationStatus, ParticipantRequest } from '../models'
import { post } from '../connector'

export class ParticipantRequestService implements IParticipantRequestService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_USERS_SERVICE_API_HOST ??
            config.servers.usersServiceUrl
        // eslint-disable-next-line no-console
        console.info(
            'USERS_SERVICE_API_HOST: Using defaults, result:',
            this.apiUrl
        )
    }

    create = async (request: ParticipantRequest) => {
        const url = `${this.apiUrl}participant`
        return await post<ParticipantRequest, OperationStatus>(undefined, url, request)
    }
}
