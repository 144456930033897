import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { SignUpButtons } from './SignUpButtons'
import kristina from '../static/images/people/kristina.svg'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ParticipantsProps {}

export const Participants: React.FC<ParticipantsProps> = () => {
    const [t] = useTranslation()

    const getPerson = () => {
        return (
            <Grid item container direction="column" justifyContent="center" gap={1} style={{ width: '140px' }}>
                <Grid item container justifyContent={'center'}>
                    <img alt={'People'} src={kristina}/>
                </Grid>
                <Grid item container justifyContent={'center'}>
                    <Typography variant="body2">
                        Kristina, 24
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container gap={3} direction="column">
            <Grid item xs={12} container direction="column" gap={1}>
                <Typography variant="h1">
                    <Trans t={t}>
                        Stories of people we were able to support
                    </Trans>
                </Typography>
                <Typography variant="body1">
                    <Trans t={t}>
                        from awareness to healing
                    </Trans>
                </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start" xs={12}>
                {getPerson()}
            </Grid>
            <Grid item container gap={3} direction="row" justifyContent="flex-start" xs={12}>
                <Grid item xs={12} sm={12} md={6}>
                    <Trans t={t}>
                        If the stories of our heroes respond to you, perhaps we can support you
                    </Trans>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <SignUpButtons onSignUpClick={() => {}}/>
                </Grid>
            </Grid>
        </Grid>
    )
}
