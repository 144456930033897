import React from 'react'
import '../static/styles/App.css'
import logo from '../static/images/logo.svg'
import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'

interface AppBarProps {
    items: React.ReactNode[]
}

export const AppBar: React.FC<AppBarProps> = (props) => {
    return (
        <Grid container justifyContent={'left'} alignItems={'center'} className="App-bar">
            <Grid item xs={2} sm={4} md={6}>
                <Link to={'/'}>
                    <img alt="Teplo logo" className="App-logo" src={logo}/>
                </Link>
            </Grid>
            <Grid item xs={10} sm={8} md={6}>
                <Grid container justifyContent={'right'} alignItems={'center'}>
                    {props.items.map((item, i) => {
                        return (
                            <div key={i} className="App-bar-menuItem">
                                {item}
                            </div>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}
