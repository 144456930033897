/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import node from '../static/images/node.svg'
import connector from '../static/images/connector.svg'

export const History: React.FC = () => {
    const [t] = useTranslation()

    return (
        <Grid container direction={'column'}>
            <Grid item xs={12}>
                <Typography variant={'h5'}>
                    <Trans t={t}>
                        There should be plenty of heat
                    </Trans>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={'body1'}>
                    <Trans t={t}>
                        The teplo project was born out of an initiative project funder, with the support of IT professionals and experts working with PTSD
                    </Trans>
                </Typography>
            </Grid>
            <Grid item xs={12} container>
                <Grid item xs={12} sm={6} container>
                    <Grid item xs={12}>
                        <Grid container direction={'row'} justifyContent={'flex-start'}>
                            <Grid item>
                                <img src={node} alt={'Node'} />
                            </Grid>
                            <Grid item xs={10} container direction={'column'}>
                                <Typography variant={'body1'} fontWeight={'bold'}>
                                    <Trans t={t}>
                                        February 2022
                                    </Trans>
                                </Typography>
                                <Typography variant={'body1'}>
                                    <Trans t={t}>
                                        Problem Awareness - Project Start
                                    </Trans>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction={'row'} justifyContent={'flex-start'}>
                            <Grid item>
                                <img src={connector} alt={'Connector'} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction={'row'} justifyContent={'flex-start'}>
                            <Grid item>
                                <img src={node} alt={'Node'} />
                            </Grid>
                            <Grid item xs={10} container direction={'column'}>
                                <Typography variant={'body1'} fontWeight={'bold'}>
                                    <Trans t={t}>
                                        February 2022
                                    </Trans>
                                </Typography>
                                <Typography variant={'body1'}>
                                    <Trans t={t}>
                                        Problem Awareness - Project Start
                                    </Trans>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant={'body1'}>
                        <Trans t={t}>
                            "Since February 24, it has become clear to me that the main task of world psychology now and for the coming years precisely is the struggle to restore the mental health of every Ukrainian. It is impossible to simply hand over millions of people, families, and children to trauma
                            Otherwise, it is as if we are agreeing
                            with the antichrist."
                        </Trans>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
