import React, { FC, useState } from 'react'
import {
    Checkbox,
    FormControl, FormControlLabel, FormGroup, Grid, Typography
} from '@mui/material'
import { SignUpButtons } from './SignUpButtons'
import { useNavigate } from 'react-router-dom'
import { OverridableStringUnion } from '@mui/types'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'

interface TesterToEmailProps {
    id: string
    type: TesterType
    title: string
    question?: string
    answers: string[]
    color?: ColorType
    border?: BorderType
    headerTypography?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
}

export enum TesterType {
    small,
    long
}

export enum ColorType {
    primary,
    secondary
}

export enum BorderType {
    minimal,
    border
}

export const TesterToEmail: FC<TesterToEmailProps> = (props) => {
    const navigate = useNavigate()
    const [selected, setSelected] = useState<string[]>([])

    const onCheckedChanged = (e: any) => {
        if (e.target.checked) {
            selected.push(e.target.value)
            setSelected(selected)
        } else {
            const index = selected.indexOf(e.target.value)
            if (index >= 0) {
                setSelected(selected.slice(index, 1))
            }
        }
    }

    const header = props.headerTypography ? props.headerTypography : 'h1'

    return (
        <Grid container gap={2} direction="column" className={props.border === BorderType.minimal ? '' : 'App-card'}>
            <Typography variant={header}>
                {props.title}
            </Typography>
            {props.question && <Typography variant="body2">
                {props.question}
            </Typography>}
            <div style={{ margin: '0px 20px' }}>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        {props.type === TesterType.small && props.answers.map((answer, i) => {
                            const labelId = `${props.id}-list-label-${i}`
                            return (
                                <FormControlLabel sx={{ alignItems: 'flex-start', padding: '10px 0' }} key={i}
                                    control={<Checkbox
                                        color={props.color === ColorType.secondary ? 'secondary' : 'primary'}
                                        edge="start"
                                        value={answer}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        onChange={onCheckedChanged}
                                        sx={{
                                            marginTop: -1
                                        }}
                                    />} label={<Typography variant={'body2'}>{answer}</Typography>}
                                />
                            )
                        })}
                        {props.type === TesterType.long && <Grid container direction="row" justifyContent="flex-start">
                            {props.answers.map((answer, i) => {
                                const labelId = `${props.id}-list-label-${i}`
                                return (
                                    <Grid xs={12} sm={6} md={6} item key={i}>
                                        <FormControlLabel sx={{ alignItems: 'flex-start', padding: '10px 5px' }}
                                            control={<Checkbox
                                                color={props.color === ColorType.secondary ? 'secondary' : 'primary'}
                                                edge="start"
                                                value={answer}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                onChange={onCheckedChanged}
                                                sx={{
                                                    marginTop: -1
                                                }}
                                            />}
                                            label={<Typography variant={'body1'}>{answer}</Typography>}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>}
                    </FormGroup>
                </FormControl>
            </div>
            <SignUpButtons color={props.color} onSignUpClick={() => {
                const params = selected.join(';;;')
                if (params.length === 0) {
                    navigate('/apply#signUp')
                } else {
                    navigate(`/apply?selected=${params}#signUp`)
                }
            }} onDetailedButtonClick={() => {
                navigate('/apply')
            }}/>
        </Grid>
    )
}
