import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { YMInitializer } from 'react-yandex-metrika'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
        <div>
            <YMInitializer accounts={[91336649]} options={
                {
                    webvisor: true,
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true
                }}/>
        </div>
    </React.StrictMode>
)
