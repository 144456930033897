import React from 'react'
import { AppBar } from './AppBar'
import { Trans, useTranslation } from 'react-i18next'
import { LanguageSwitcher } from './LanguageSwitcher'
import instagram from '../static/images/instagram.svg'
import telegram from '../static/images/telegram.svg'
import config from '../config.json'
import { useFeatureFlagService } from '../hooks/use-feature-flag-service'
import { FeatureFlag } from '../services/feature-flag-service'
import { useRoutesService } from '../hooks/use-routes-service'
import { RoutePath } from '../services/routes-service'
import { Box, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDeviceSelectors } from 'react-device-detect'

interface MenuProps {
    selected: MenuItem
}

export enum MenuItem {
    Home,
    About,
    Request
}

export const Menu: React.FC<MenuProps> = (props) => {
    const [t] = useTranslation()
    const [featureFlagService] = useFeatureFlagService()
    const [routesService] = useRoutesService()
    const navigate = useNavigate()
    const [selectors] = useDeviceSelectors(window.navigator.userAgent)
    const { isMobile } = selectors

    const getClass = (selected: boolean) => {
        return `menuLink${selected ? ' selected' : ''}`
    }

    const getItems = () => {
        const res: React.ReactNode[] = []
        if (featureFlagService.getFlag(FeatureFlag.MainPage) && (!isMobile || props.selected !== MenuItem.Home)) {
            res.push(
                <Box justifyContent={'center'} alignItems={'center'}>
                    <Link typography={'body1'}
                        className={getClass(props.selected === MenuItem.Home)}
                        href={routesService.getPath(RoutePath.Main)}
                        onClick={(e) => {
                            e.preventDefault()
                            navigate(routesService.getPath(RoutePath.Main))
                        }}
                    >
                        <Trans t={t}>
                            Home
                        </Trans>
                    </Link>
                </Box>
            )
        }
        if (featureFlagService.getFlag(FeatureFlag.About)) {
            res.push(
                <Box justifyContent={'center'} alignItems={'center'}>
                    <Link typography={'body1'}
                        className={getClass(props.selected === MenuItem.About)}
                        href={routesService.getPath(RoutePath.About)}
                        onClick={(e) => {
                            e.preventDefault()
                            navigate(routesService.getPath(RoutePath.About))
                        }}>
                        <Trans t={t}>
                            About us
                        </Trans>
                    </Link>
                </Box>
            )
        }
        if ((featureFlagService.getFlag(FeatureFlag.MainPage) ||
            featureFlagService.getFlag(FeatureFlag.About)) && (!isMobile || props.selected !== MenuItem.Request)) {
            res.push(
                <Box justifyContent={'center'} alignItems={'center'}>
                    <Link typography={'body1'}
                        className={getClass(props.selected === MenuItem.Request)}
                        href={routesService.getPath(RoutePath.Apply)}
                        onClick={(e) => {
                            e.preventDefault()
                            navigate(routesService.getPath(RoutePath.Apply))
                        }}>
                        <Trans t={t}>
                            To apply
                        </Trans>
                    </Link>
                </Box>
            )
        }
        res.push(<LanguageSwitcher/>)
        res.push(
            <Box justifyContent={'center'} alignItems={'center'}>
                {config.messengers.instagram && <a href={config.messengers.instagram} target={'_blank'} rel="noreferrer">
                    <img src={instagram} alt={'Instagram'} width={32} style={{ verticalAlign: 'bottom' }}/>
                </a>}
                {config.messengers.telegram && <img src={telegram} alt={'Telegram'} style={{ verticalAlign: 'bottom' }} />}
            </Box>)
        return res
    }

    return (
        <React.Fragment>
            <div className="App-bar-container">
                <AppBar items={getItems()}/>
            </div>
        </React.Fragment>
    )
}
