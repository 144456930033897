import React from 'react'
import { Button, Grid } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { ColorType } from './TesterToEmail'

interface SignUpButtonsProps {
    color?: ColorType
    onSignUpClick: () => void
    hideDetailedButton?: boolean
    onDetailedButtonClick?: () => void
    fullWidth?: boolean
}

export const SignUpButtons: React.FC<SignUpButtonsProps> = (props) => {
    const [t] = useTranslation()

    return (
        <Grid container gap={2}>
            <Button fullWidth={props.fullWidth} color={props.color === ColorType.secondary ? 'secondary' : 'primary'} variant={'outlined'} onClick={props.onSignUpClick}>
                <Trans t={t}>
                    Sign up for a course
                </Trans>
            </Button>
            {!props.hideDetailedButton && <Button
                color={props.color === ColorType.secondary ? 'secondary' : 'primary'}
                sx={{ fontWeight: 400 }}
                onClick={props.onDetailedButtonClick}>
                <Trans t={t}>
                    Learn more about the course
                </Trans>
            </Button>}
        </Grid>
    )
}
