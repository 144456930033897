import React from 'react'
import { Container, Grid } from '@mui/material'
import { FeatureFlag } from '../services/feature-flag-service'
import { useFeatureFlagService } from '../hooks/use-feature-flag-service'

interface WidgetBlockProps {
    white: boolean
    background?: boolean
    featureFlag?: FeatureFlag
    children?: React.ReactNode
    id?: string
}

export const WidgetBlock: React.FC<WidgetBlockProps> = (props) => {
    const [featureFlagService] = useFeatureFlagService()
    let style: React.CSSProperties | undefined = {}
    if (props.background) {
        style = {
            backgroundImage: 'url(/background.svg)',
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat'
        }
    }
    const className = props.white ? 'App-separator white-box' : 'App-separator'

    const isVisible = () => {
        return props.featureFlag === undefined || featureFlagService.getFlag(props.featureFlag)
    }

    return (
        <React.Fragment>
            { isVisible() && <Grid item container xs={12} justifyContent="center" alignItems="center"
                className={className} style={style} id={props.id}>
                <Container>
                    {props.children}
                </Container>
            </Grid>}
        </React.Fragment>

    )
}
