/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Grid, Typography } from '@mui/material'
import { WarningBanner } from './WarningBanner'
import { Trans, useTranslation } from 'react-i18next'
import location from '../static/images/location.svg'
import { SignUpButtons } from './SignUpButtons'
import { ColorType } from './TesterToEmail'
import bannerImage5 from '../static/images/banner/banner-image-5.svg'
import { useDeviceSelectors } from 'react-device-detect'
import { useRoutesService } from '../hooks/use-routes-service'
import { useNavigate } from 'react-router-dom'
import { WidgetBlock } from './WidgetBlock'

export const SignUpBanner: React.FC = () => {
    const [selectors] = useDeviceSelectors(window.navigator.userAgent)
    const { isMobile } = selectors
    const [routesService] = useRoutesService()
    const [t] = useTranslation()
    const navigate = useNavigate()

    const getBanner = () => {
        return (
            <Grid container>
                <Grid item xs={6} container gap={2} direction={'column'}>
                    {!isMobile && <div style={{ height: '30px' }}></div>}
                    <div>
                        <WarningBanner text={<Typography component="span"
                            noWrap={true}
                            variant={'h1'}>
                            <Trans t={t}>
                                Signing up for the program
                            </Trans>
                        </Typography>}/>
                    </div>
                    <div>
                        <WarningBanner text={<Typography component="span"
                            noWrap={true}
                            variant={'h1'}>
                            <Trans t={t}>
                                "Artful Odyssey."
                            </Trans>
                        </Typography>}/>
                    </div>
                    <Typography variant="body2">
                        <img width={16} src={location} style={{ verticalAlign: 'text-top', marginRight: '5px' }}
                            alt={'Location'}/>
                        <Trans t={t}>
                            online, free
                        </Trans>
                    </Typography>
                    {!isMobile && <SignUpButtons color={ColorType.secondary} hideDetailedButton={true} onSignUpClick={() => {
                        navigate(routesService.signUp())
                    }}/>}
                </Grid>
                <Grid item xs={6}>
                    <img width={'100%'} src={bannerImage5} alt={'Image 5'}/>
                </Grid>
                {isMobile && <Grid item xs={12}>
                    <div style={{ height: '15px' }}></div>
                    <SignUpButtons fullWidth={true} color={ColorType.secondary} hideDetailedButton={true} onSignUpClick={() => {
                        navigate(routesService.signUp())
                    }}/>
                </Grid>}
            </Grid>
        )
    }

    return (
        <React.Fragment>
            {isMobile && <div style={{ paddingTop: '40px', paddingLeft: '15px', paddingBottom: '20px', paddingRight: '15px' }}>
                {getBanner()}
            </div>}
            {!isMobile && <WidgetBlock white={false}>
                {getBanner()}
            </WidgetBlock>}
        </React.Fragment>
    )
}
