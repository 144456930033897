import { IThemeFactory } from '../theme-factory'
import { useDeviceSelectors } from 'react-device-detect'
import { createTheme } from '@mui/material'

export class ThemeFactory implements IThemeFactory {
    get = () => {
        const [selectors] = useDeviceSelectors(window.navigator.userAgent)
        const { isMobile } = selectors
        return isMobile ? this.themes.mobile : this.themes.main
    }

    private readonly getMain = () => {
        return createTheme({
            typography: {
                fontFamily: "'Gilroy-Medium', sans-serif",
                fontSize: 16,
                h1: {
                    fontWeight: 700,
                    fontSize: '1.7em',
                    lineHeight: '1.3em',
                    fontFamily: "'Gilroy-Bold', sans-serif"
                },
                h2: {
                    fontWeight: 600,
                    fontSize: '1.6em',
                    lineHeight: '1.2em'
                },
                h3: {
                    fontWeight: 600,
                    fontStyle: 'italic',
                    fontSize: '1.6em',
                    lineHeight: '1.2em',
                    fontFamily: "'Gilroy-Bold', sans-serif"
                },
                subtitle1: {
                    fontStyle: 'italic',
                    fontWeight: 500,
                    fontSize: '1.2em',
                    lineHeight: '1.3em'
                },
                body1: {
                    fontSize: '1.1em',
                    lineHeight: '1.4em',
                    fontWeight: 500
                },
                body2: {
                    fontWeight: 500,
                    fontSize: '0.9em',
                    lineHeight: '1.3em'
                }
            },
            palette: {
                mode: 'light',
                background: {
                    default: '#F5F5F5'
                },
                primary: {
                    main: '#D58DA7'
                },
                secondary: {
                    main: '#775DA6'
                }
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontSize: '0.9em',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            textTransform: 'none',
                            lineHeight: '1em'
                        }
                    }
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            fontSize: '0.9em',
                            fontWeight: 500
                        }
                    }
                },
                MuiLink: {
                    styleOverrides: {
                        root: {
                            textDecoration: 'none'
                        }
                    }
                }
            }
        })
    }

    private readonly getMobile = () => {
        return createTheme({
            typography: {
                fontFamily: "'Gilroy-Medium', sans-serif",
                fontSize: 16,
                h1: {
                    fontWeight: 700,
                    fontSize: '1.1em',
                    lineHeight: '2em',
                    fontFamily: "'Gilroy-Bold', sans-serif"
                },
                h2: {
                    fontWeight: 600,
                    fontSize: '1em',
                    lineHeight: '1.4em'
                },
                h3: {
                    fontWeight: 600,
                    fontStyle: 'italic',
                    fontSize: '1em',
                    lineHeight: '1.4em',
                    fontFamily: "'Gilroy-Bold', sans-serif"
                },
                subtitle1: {
                    fontStyle: 'italic',
                    fontWeight: 500,
                    fontSize: '1em',
                    lineHeight: '1.3em'
                },
                body1: {
                    fontSize: '1em',
                    lineHeight: '1.4em',
                    fontWeight: 500
                },
                body2: {
                    fontWeight: 500,
                    fontSize: '0.9em',
                    lineHeight: '1.3em'
                }
            },
            palette: {
                mode: 'light',
                background: {
                    default: '#F5F5F5'
                },
                primary: {
                    main: '#D58DA7'
                },
                secondary: {
                    main: '#775DA6'
                }
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontSize: '0.9em',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            textTransform: 'none',
                            lineHeight: '1em'
                        }
                    }
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            fontSize: '0.9em',
                            fontWeight: 500
                        }
                    }
                },
                MuiLink: {
                    styleOverrides: {
                        root: {
                            textDecoration: 'none'
                        }
                    }
                }
            }
        })
    }

    private readonly themes = {
        main: this.getMain(),
        mobile: this.getMobile()
    }
}
