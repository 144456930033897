import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { Menu, MenuItem } from '../components/Menu'
import { useParams } from 'react-router-dom'
import { WidgetBlock } from '../components/WidgetBlock'
import { Article } from '../services/models'
import { useArticleService } from '../hooks/use-article-service'

export const Blog: React.FC = () => {
    const { id } = useParams()
    const [article, setArticle] = useState<Article>()
    const [articleService] = useArticleService()

    useEffect(() => {
        if (id) {
            void articleService.find(undefined, {
                id: [id]
            }).then(res => {
                res.map(a => {
                    if (a.items.length > 0) {
                        setArticle(a.items[0])
                    }
                })
            })
        }
    }, [id])

    return (
        <Grid container direction="column" className="App">
            <Grid item xs={12}>
                <Menu selected={MenuItem.Home} />
            </Grid>
            {article && <WidgetBlock white={false}>
                <div dangerouslySetInnerHTML={{ __html: article.body }} />
            </WidgetBlock>}
        </Grid>
    )
}
