import { IRoutesService, RoutePath } from '../routes-service'
import { FeatureFlag, IFeatureFlagService } from '../feature-flag-service'

export class RoutesService implements IRoutesService {
    private readonly map = {
        home: '/',
        about: '/about',
        apply: '/apply',
        blog: '/blog/:id'
    }

    private readonly featureFlagService: IFeatureFlagService

    constructor (featureFlagService: IFeatureFlagService) {
        this.featureFlagService = featureFlagService
    }

    getPath = (path: RoutePath) => {
        switch (path) {
            case RoutePath.Main:
                return this.map.home
            case RoutePath.About:
                return this.map.about
            case RoutePath.Apply:
                return this.featureFlagService.getFlag(FeatureFlag.MainPage) ? this.map.apply : this.map.home
            case RoutePath.Blog:
                return this.map.blog
        }
    }

    signUp = () => {
        return this.featureFlagService.getFlag(FeatureFlag.MainPage) ? `${this.getPath(RoutePath.Apply)}#signUp` : '/#signUp'
    }
}
