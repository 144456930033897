import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

export const activeLanguages = ['ru', 'en', 'uk']
export const activeLanguagesNames: Record<string, string> = {
    ru: 'ru',
    en: 'en',
    uk: 'ua'
}

void i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        ns: ['common'],

        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: '/i18n/locales/{{lng}}/{{ns}}.json'
        }
    })

i18n.on('languageChanged', function (lng) {
    let currentLanaguage = lng
    if (currentLanaguage && currentLanaguage.indexOf('-') > 0) {
        currentLanaguage = currentLanaguage.split('-')[0]
    }

    if (!activeLanguages.includes(currentLanaguage)) {
        currentLanaguage = 'en'
    }

    if (currentLanaguage !== lng) {
        void i18n.changeLanguage(currentLanaguage)
    }
})

export default i18n
