/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-extra-boolean-cast */
import React, { useState } from 'react'
import {
    Alert,
    Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel, Link,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography, InputAdornment
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { useParticipantRequestService } from '../hooks/use-participant-request-service'
import { ContactType, ParticipantRequest } from '../services/models'
import config from '../config.json'
import { Link as LinkRoute } from 'react-router-dom'
import { useCaptchaContext } from '../hooks/CaptchaContext'
import { Apokrif } from './controls/Apokrif'

enum HelpType {
    VolunteerSpecialist = 'VolunteerSpecialist',
    Donation = 'Donation',
    Information = 'Information',
    Report = 'Report',
    Other = 'Other'
}

export const MessageForm: React.FC = () => {
    const [t] = useTranslation()
    const [preferredCommunicationMode, setPreferredCommunicationMode] = useState(ContactType.Telegram)
    const [helpType, setHelpType] = useState(HelpType.VolunteerSpecialist)
    const {
        register,
        handleSubmit,
        reset,
        control
    } = useForm()
    const [participantRequestService] = useParticipantRequestService()
    const [error, setError] = useState<string>()
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const { recaptchaRef } = useCaptchaContext()

    const resetForm = () => {
        reset()
        recaptchaRef?.current?.reset()
    }

    const onSubmit = async (form: FieldValues) => {
        if (!recaptchaRef) {
            setError(t('Captcha control not founded'))
            return
        }

        setError(undefined)

        let token

        try {
            token = await recaptchaRef.current?.executeAsync()

            if (!token) {
                setError(t('Captcha token is empty'))
                return
            }
        } catch (e) {
            setError(JSON.stringify(e))
            return
        }

        const request: ParticipantRequest = {
            name: form.name,
            typeOfContact: preferredCommunicationMode,
            nickname: form.email,
            participantType: helpType,
            feedback: form.comments,
            captcha: token
        }
        setLoading(true)
        const r = await participantRequestService.create(request)
        r.map(status => {
            if (!status.succeeded) {
                setError(status.error)
                recaptchaRef?.current?.reset()
            } else {
                setConfirmOpen(true)
                resetForm()
            }
        }).mapError(e => {
            setError(e.error)
            recaptchaRef?.current?.reset()
        })
        setLoading(false)
    }

    const getEmailLabel = () => {
        switch (preferredCommunicationMode) {
            case ContactType.Telegram:
                return t('@nickname')
            case ContactType.Viber:
                return t('Nickname')
            default:
                return t('E-mail')
        }
    }

    return (
        <React.Fragment>
            <Grid container direction={'row'}>
                <Grid item xs={12} sm={12} md={6} container>
                    <Grid item xs={12} container gap={3} direction="column" style={{ paddingRight: '30px', paddingBottom: '10px' }}>
                        <Typography variant={'h1'}>
                            <Trans t={t}>
                                We want to be warm friends with you
                            </Trans>
                        </Typography>
                        <Typography variant={'body1'}>
                            <Trans t={t}>
                                Contact us if
                            </Trans>
                        </Typography>
                        <ul>
                            <li>
                                <Trans t={t}>
                                    Would you like to enroll in our <LinkRoute className="routeLink" to={'/apply#signUp'}>"Artful Odyssey"</LinkRoute> course?
                                </Trans>
                            </li>
                            <li>
                                <Trans t={t}>
                                    You want to receive important information about life in the world of trauma or
                                    project news
                                </Trans>
                            </li>
                            <li>
                                <Trans t={t}>
                                    You can/want to help the project (send a donation/volunteer/help develop the
                                    project/become a partner)
                                </Trans>
                            </li>
                        </ul>
                        <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
                            <Trans t={t}>
                                As long as there is violence, your contribution to the project will be
                                relevant
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} direction={'column'}>
                    <Box
                        component="form"
                        method="POST"
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <FormControl fullWidth={true} sx={{ mb: 1 }}>
                            <Controller
                                name={'name'}
                                control={control}
                                rules={{ validate: async (value) => !!value?.trim() ? true : await t('Name is required') }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field}
                                        fullWidth={true}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        disabled={loading}
                                        label={t('Name')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start"><Apokrif /></InputAdornment>
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl sx={{ mb: 1 }}>
                            <FormLabel>
                                <Typography variant={'body2'}>
                                    <Trans t={t}>
                                        Preferred mode of communication
                                    </Trans>
                                </Typography>
                            </FormLabel>
                            <RadioGroup
                                value={preferredCommunicationMode}
                                row={true}
                                {...register('preferredCommunicationMode', {
                                    onChange: (e) => {
                                        setPreferredCommunicationMode(e.currentTarget.value)
                                    }
                                })}>
                                <FormControlLabel value={ContactType.Telegram}
                                    componentsProps={{ typography: { variant: 'body2' } }}
                                    control={<Radio size={'small'} disabled={loading}/>}
                                    label={t('Telegram')}/>
                                <FormControlLabel value={ContactType.Viber}
                                    componentsProps={{ typography: { variant: 'body2' } }}
                                    control={<Radio size={'small'} disabled={loading}/>}
                                    label={t('Viber')}/>
                                <FormControlLabel value={ContactType.Email}
                                    componentsProps={{ typography: { variant: 'body2' } }}
                                    control={<Radio size={'small'} disabled={loading}/>}
                                    label={t('E-mail')}/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl fullWidth={true} sx={{ mb: 1 }}>
                            <Controller
                                name={'email'}
                                control={control}
                                rules={{ validate: async (value) => !!value?.trim() ? true : await t('Field is required') }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field}
                                        fullWidth={true}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        disabled={loading}
                                        label={getEmailLabel()}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start"><Apokrif /></InputAdornment>
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl sx={{ mb: 1 }}>
                            <FormLabel>
                                <Typography variant={'body2'}>
                                    <Trans t={t}>
                                        You can help the project as
                                    </Trans>
                                </Typography>
                            </FormLabel>
                            <RadioGroup
                                value={helpType}
                                row={true}
                                {...register('helpType', {
                                    onChange: (e) => {
                                        setHelpType(e.currentTarget.value)
                                    }
                                })}
                            >
                                <FormControlLabel value={HelpType.VolunteerSpecialist}
                                    componentsProps={{ typography: { variant: 'body2' } }}
                                    control={<Radio size={'small'} disabled={loading}/>}
                                    label={t('Volunteer Specialist')}/>
                                <FormControlLabel value={HelpType.Donation}
                                    componentsProps={{ typography: { variant: 'body2' } }}
                                    control={<Radio size={'small'} disabled={loading}/>}
                                    label={t('Send a donation to the project')}/>
                                <FormControlLabel value={HelpType.Information}
                                    componentsProps={{ typography: { variant: 'body2' } }}
                                    control={<Radio size={'small'} disabled={loading}/>}
                                    label={t('Informational support')}/>
                                <FormControlLabel value={HelpType.Report}
                                    componentsProps={{ typography: { variant: 'body2' } }}
                                    control={<Radio size={'small'} disabled={loading}/>}
                                    label={t('Leave feedback on the course/project')}/>
                                <FormControlLabel value={HelpType.Other}
                                    componentsProps={{ typography: { variant: 'body2' } }}
                                    control={<Radio size={'small'} disabled={loading}/>}
                                    label={t('Another form of cooperation')}/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl fullWidth={true} sx={{ mb: 2 }}>
                            <Controller
                                name={'comments'}
                                control={control}
                                render={({ field }) => (
                                    <TextField {...field}
                                        fullWidth={true}
                                        disabled={loading}
                                        label={t('Comments')}
                                        multiline={true}
                                        rows={5}
                                    />
                                )}
                            />
                        </FormControl>
                        {error && <FormControl fullWidth={true} sx={{ mb: 2 }}>
                            <Alert severity="error">{error}</Alert>
                        </FormControl>}
                        <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'} gap={3}>
                            <Grid item container xs={12}>
                                <Button disabled={loading} type={'submit'} style={{ height: '40px' }} fullWidth={true} variant={'contained'} sx={{
                                    color: 'white'
                                }}>
                                    <Trans t={t}>
                                        Submit
                                    </Trans>
                                </Button>
                            </Grid>
                            {config.messengers.telegram && <Link>
                                <Trans t={t}>
                                    Our Telegram bot
                                </Trans>
                            </Link>}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={confirmOpen} onClose={() => {
                setConfirmOpen(false)
            }}>
                <DialogTitle>
                    <Trans t={t}>
                        Message has been sent
                    </Trans>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans t={t}>
                            Thank you for request! We will contact you soon
                        </Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setConfirmOpen(false)
                    }}>
                        <Trans t={t}>
                            Ok
                        </Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
