import React from 'react'
import { Grid } from '@mui/material'
import { Menu, MenuItem } from '../components/Menu'
import { WidgetBlock } from '../components/WidgetBlock'
import { History } from '../components/History'

export const About: React.FC = () => {
    return (
        <Grid container direction="column" className="App">
            <Grid item xs={12}>
                <Menu selected={MenuItem.About} />
            </Grid>
            <WidgetBlock white={false}>
                <History />
            </WidgetBlock>
        </Grid>
    )
}
